<template>
  <div v-if="page" class="page">

    <div class="sticky-bottom-bar fixed bottom-0 left-0 right-0 transition-all duration-300 p-12 hidden lg:flex md:justify-between lg:items-center border-t border-black">
      <div class="title">
        <h4 class="font-bold uppercase">{{ page.elements.title.value }}</h4>
      </div>
      <div class="details flex items-center">

        <div class="item flex">
          <div class="icon shrink-0 w-10 mt-px">
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.6875 1.8125V2.75H8.4375V1.8125C8.4375 1.31445 8.84766 0.875 9.375 0.875C9.87305 0.875 10.3125 1.31445 10.3125 1.8125V2.75H11.7188C12.4805 2.75 13.125 3.39453 13.125 4.15625V5.5625H0V4.15625C0 3.39453 0.615234 2.75 1.40625 2.75H2.8125V1.8125C2.8125 1.31445 3.22266 0.875 3.75 0.875C4.24805 0.875 4.6875 1.31445 4.6875 1.8125ZM0 6.5H13.125V14.4688C13.125 15.2598 12.4805 15.875 11.7188 15.875H1.40625C0.615234 15.875 0 15.2598 0 14.4688V6.5ZM1.875 9.78125C1.875 10.0449 2.08008 10.25 2.34375 10.25H3.28125C3.51562 10.25 3.75 10.0449 3.75 9.78125V8.84375C3.75 8.60938 3.51562 8.375 3.28125 8.375H2.34375C2.08008 8.375 1.875 8.60938 1.875 8.84375V9.78125ZM5.625 9.78125C5.625 10.0449 5.83008 10.25 6.09375 10.25H7.03125C7.26562 10.25 7.5 10.0449 7.5 9.78125V8.84375C7.5 8.60938 7.26562 8.375 7.03125 8.375H6.09375C5.83008 8.375 5.625 8.60938 5.625 8.84375V9.78125ZM9.84375 8.375C9.58008 8.375 9.375 8.60938 9.375 8.84375V9.78125C9.375 10.0449 9.58008 10.25 9.84375 10.25H10.7812C11.0156 10.25 11.25 10.0449 11.25 9.78125V8.84375C11.25 8.60938 11.0156 8.375 10.7812 8.375H9.84375ZM1.875 13.5312C1.875 13.7949 2.08008 14 2.34375 14H3.28125C3.51562 14 3.75 13.7949 3.75 13.5312V12.5938C3.75 12.3594 3.51562 12.125 3.28125 12.125H2.34375C2.08008 12.125 1.875 12.3594 1.875 12.5938V13.5312ZM6.09375 12.125C5.83008 12.125 5.625 12.3594 5.625 12.5938V13.5312C5.625 13.7949 5.83008 14 6.09375 14H7.03125C7.26562 14 7.5 13.7949 7.5 13.5312V12.5938C7.5 12.3594 7.26562 12.125 7.03125 12.125H6.09375ZM9.375 13.5312C9.375 13.7949 9.58008 14 9.84375 14H10.7812C11.0156 14 11.25 13.7949 11.25 13.5312V12.5938C11.25 12.3594 11.0156 12.125 10.7812 12.125H9.84375C9.58008 12.125 9.375 12.3594 9.375 12.5938V13.5312Z" fill="#E8490F"/>
            </svg>
          </div>
          <div class="text">
            <!-- {{dayjs.utc(page.elements.start_date.value).tz(page.elements.start_date.display_timezone).format('DD')}} - {{dayjs.utc(page.elements.end_date.value).tz(page.elements.end_date.display_timezone).format('DD MMMM YYYY')}} -->
            <div v-if="page.elements.end_date.value && page.elements.end_date.value !== page.elements.start_date.value">
              {{dayjs.utc(page.elements.start_date.value).tz(page.elements.start_date.display_timezone).format('DD')}} - {{dayjs.utc(page.elements.end_date.value).tz(page.elements.start_date.display_timezone).format('DD MMMM YYYY')}}
            </div>
            <div v-else>
              {{dayjs.utc(page.elements.start_date.value).tz(page.elements.start_date.display_timezone).format('DD MMMM YYYY')}}
            </div>

            <div v-if="page.elements.ics.value.length">
              <a :href="page.elements.ics.value[0].url" target="_blank" class="text-primary-500 hover:text-white transition-all">Add to calendar</a>
            </div>
          </div>
        </div>

        <div class="item flex ml-20">
          <div class="icon shrink-0 w-10 mt-1">
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.25 6.5C11.25 9.07812 7.82227 13.6191 6.29883 15.5234C5.94727 15.9629 5.27344 15.9629 4.92188 15.5234C3.39844 13.6191 0 9.07812 0 6.5C0 3.39453 2.49023 0.875 5.625 0.875C8.73047 0.875 11.25 3.39453 11.25 6.5Z" fill="#E8490F"/>
            </svg>
          </div>
          <div class="text">
            {{page.elements.location.value}}<br>
            {{page.elements.city.value}}, {{page.elements.country.value}}
          </div>
        </div>

        <div class="ml-20" v-if="page.elements.attend_event_url.value.length">
          <a :href="page.elements.attend_event_url.value" target="_blank" class="btn-white inline-block">Attend event</a>
        </div>
      </div>
    </div>

    <div class="outer-container">
      <div class="inner-container">


        <div class="breadcrumbs mt-12 text-grey-400 text-12 uppercase">
          <router-link to="/experience" class="hover:text-white transition-colors">Experience</router-link>
          <span class="divider inline-block px-4">&gt;</span><span
            class="text-white">{{ page.elements.title.value }}</span>
        </div>


        <div class="hero mt-12 pb-16 md:pb-36 relative">
          <h1 class="max-w-7xl font-bold uppercase relative z-20">{{ page.elements.title.value }}</h1>
          <bleed-container bleed="left" class="hero-image-height -mt-8 relative z-10">
            <div class="hero-image absolute left-0 top-0 w-full h-full z-10">
              <img v-if="page.elements.image.value.length"
                   :data-lowsrc="`${page.elements.image.value[0].url}?w=100&auto=format`"
                   :data-srcset="`${page.elements.image.value[0].url}?w=767&h=250&fit=crop&auto=format 767w, ${page.elements.image.value[0].url}?w=1000&h=450&fit=crop&auto=format 1023w, ${page.elements.image.value[0].url}?w=2000&auto=format 2000w`"
                   alt="Winery Name" class="lazyload block absolute left-0 top-0 w-full h-full object-cover max-w-none">
            </div>
            <div class="bleed-wrapper relative z-20 h-full" v-if="page.elements.logo.value.length">
              <div class="logo-box bg-black p-6 md:p-16 lg:p-20 absolute right-0 -bottom-16 md:-bottom-36">
                <div class="logo relative p-8 w-40 h-40 md:w-64 md:h-64 lg:w-100 lg:h-100">
                  <div class="logo-img absolute left-8 top-8 right-8 bottom-8">
                    <img :src="page.elements.logo.value[0].url" :alt="`${page.elements.title.value} logo`"
                         class="absolute object-contain object-center left-0 top-0 w-full h-full">
                  </div>
                </div>
              </div>
            </div>
          </bleed-container>
        </div>


        <div class="main-content relative mt-12">
          <div class="row flex flex-col-reverse md:flex-row md:items-start">

            <div class="main flex-grow">

              <!-- intro text -->
              <div>
                <div class="intro-text" v-html="page.elements.intro_text.value"></div>
              </div>

              <!-- main text -->
              <div>
                <div class="main-text mt-12" v-html="this.$options.filters.richtext(page.elements.main_text.value, modular_content)"></div>
              </div>

              <!-- downloads -->
              <div class="mt-32">
                <div class="downloads -m-4 flex flex-row flex-wrap">
                  <div class="download p-4 w-full sm:w-1/2" v-if="page.elements.exhibitors.value.length">
                    <a :href="page.elements.exhibitors.value[0].url" target="_blank"
                       class="overflow-hidden flex flex-row w-full items-center border border-grey-100">
                      <div class="icon bg-primary-500 p-4 md:p-8 flex-shrink-0">
                        <img src="/img/icon-file.png" alt="" class="block w-8">
                      </div>
                      <div class="download-inner px-4 py-2 md:px-8 overflow-ellipsis" v-html="page.elements.exhibitors_label && page.elements.exhibitors_label.value && page.elements.exhibitors_label.value.length ? page.elements.exhibitors_label.value : 'Download exhibitors'"></div>
                    </a>
                  </div>
                  <div class="download p-4 w-full sm:w-1/2" v-if="page.elements.floorplan.value.length">
                    <a :href="page.elements.floorplan.value[0].url" target="_blank"
                       class="overflow-hidden flex flex-row w-full items-center border border-grey-100">
                      <div class="icon bg-primary-500 p-4 md:p-8 flex-shrink-0">
                        <img src="/img/icon-file.png" alt="" class="block w-8">
                      </div>
                      <div class="download-inner px-4 py-2 md:px-8 overflow-ellipsis" v-html="page.elements.floorplan_label && page.elements.floorplan_label.value && page.elements.floorplan_label.value.length ? page.elements.floorplan_label.value : 'Download floorplan'"></div>
                    </a>
                  </div>
                  <div class="download p-4 w-full sm:w-1/2" v-if="page.elements.program.value.length">
                    <a :href="page.elements.program.value[0].url" target="_blank"
                       class="overflow-hidden flex flex-row w-full items-center border border-grey-100">
                      <div class="icon bg-primary-500 p-4 md:p-8 flex-shrink-0">
                        <img src="/img/icon-file.png" alt="" class="block w-8">
                      </div>
                      <div class="download-inner px-4 py-2 md:px-8 overflow-ellipsis" v-html="page.elements.program_label && page.elements.program_label.value && page.elements.program_label.value.length ? page.elements.program_label.value : 'Download program'"></div>
                    </a>
                  </div>
                  <div class="download p-4 w-full sm:w-1/2" v-if="page.elements.media_assets.value.length">
                    <a :href="page.elements.media_assets.value[0].url" target="_blank"
                       class="overflow-hidden flex flex-row w-full items-center border border-grey-100">
                      <div class="icon bg-primary-500 p-4 md:p-8 flex-shrink-0">
                        <img src="/img/icon-file.png" alt="" class="block w-8">
                      </div>
                      <div class="download-inner px-4 py-2 md:px-8 overflow-ellipsis" v-html="page.elements.media_assets_label && page.elements.media_assets_label.value && page.elements.media_assets_label.value.length ? page.elements.media_assets_label.value : 'Download media assets'"></div>
                    </a>
                  </div>
                </div>
              </div>

              <winery-video :video-url="page.elements.video_url.value" class="mt-32"
                            v-if="page.elements.video_url.value.length"/>
            </div>

            <aside
                class="mb-16 md:mb-0 block md:sticky md:top-40 lg:top-60 md:w-96 lg:w-380 flex-shrink-0 md:ml-16 lg:ml-24 xl:ml-56">
              <bleed-container bleed="right">
                <div class="bg-grey-100 p-8 lg:p-16">
                  <div class="bleed-wrapper">
                    <div>
                      <div class="opacity-50">Date</div>
                      <div class="item flex mt-4">
                        <div class="icon shrink-0 w-10 mt-px">
                          <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.6875 1.8125V2.75H8.4375V1.8125C8.4375 1.31445 8.84766 0.875 9.375 0.875C9.87305 0.875 10.3125 1.31445 10.3125 1.8125V2.75H11.7188C12.4805 2.75 13.125 3.39453 13.125 4.15625V5.5625H0V4.15625C0 3.39453 0.615234 2.75 1.40625 2.75H2.8125V1.8125C2.8125 1.31445 3.22266 0.875 3.75 0.875C4.24805 0.875 4.6875 1.31445 4.6875 1.8125ZM0 6.5H13.125V14.4688C13.125 15.2598 12.4805 15.875 11.7188 15.875H1.40625C0.615234 15.875 0 15.2598 0 14.4688V6.5ZM1.875 9.78125C1.875 10.0449 2.08008 10.25 2.34375 10.25H3.28125C3.51562 10.25 3.75 10.0449 3.75 9.78125V8.84375C3.75 8.60938 3.51562 8.375 3.28125 8.375H2.34375C2.08008 8.375 1.875 8.60938 1.875 8.84375V9.78125ZM5.625 9.78125C5.625 10.0449 5.83008 10.25 6.09375 10.25H7.03125C7.26562 10.25 7.5 10.0449 7.5 9.78125V8.84375C7.5 8.60938 7.26562 8.375 7.03125 8.375H6.09375C5.83008 8.375 5.625 8.60938 5.625 8.84375V9.78125ZM9.84375 8.375C9.58008 8.375 9.375 8.60938 9.375 8.84375V9.78125C9.375 10.0449 9.58008 10.25 9.84375 10.25H10.7812C11.0156 10.25 11.25 10.0449 11.25 9.78125V8.84375C11.25 8.60938 11.0156 8.375 10.7812 8.375H9.84375ZM1.875 13.5312C1.875 13.7949 2.08008 14 2.34375 14H3.28125C3.51562 14 3.75 13.7949 3.75 13.5312V12.5938C3.75 12.3594 3.51562 12.125 3.28125 12.125H2.34375C2.08008 12.125 1.875 12.3594 1.875 12.5938V13.5312ZM6.09375 12.125C5.83008 12.125 5.625 12.3594 5.625 12.5938V13.5312C5.625 13.7949 5.83008 14 6.09375 14H7.03125C7.26562 14 7.5 13.7949 7.5 13.5312V12.5938C7.5 12.3594 7.26562 12.125 7.03125 12.125H6.09375ZM9.375 13.5312C9.375 13.7949 9.58008 14 9.84375 14H10.7812C11.0156 14 11.25 13.7949 11.25 13.5312V12.5938C11.25 12.3594 11.0156 12.125 10.7812 12.125H9.84375C9.58008 12.125 9.375 12.3594 9.375 12.5938V13.5312Z" fill="#E8490F"/>
                          </svg>
                        </div>
                        <div class="text">
                          <div v-if="page.elements.end_date.value && page.elements.end_date.value !== page.elements.start_date.value">
                            {{dayjs.utc(page.elements.start_date.value).tz(page.elements.start_date.display_timezone).format('DD')}} - {{dayjs.utc(page.elements.end_date.value).tz(page.elements.start_date.display_timezone).format('DD MMMM YYYY')}}
                          </div>
                          <div v-else>
                            {{dayjs.utc(page.elements.start_date.value).tz(page.elements.start_date.display_timezone).format('DD MMMM YYYY')}}
                          </div>
                          <div v-if="page.elements.ics.value.length">
                            <a :href="page.elements.ics.value[0].url" target="_blank" class="text-primary-500 hover:text-white transition-all">Add to calendar</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-12">
                      <div class="opacity-50">Location</div>
                      <div class="item flex mt-4">
                        <div class="icon shrink-0 w-10 mt-1">
                          <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.25 6.5C11.25 9.07812 7.82227 13.6191 6.29883 15.5234C5.94727 15.9629 5.27344 15.9629 4.92188 15.5234C3.39844 13.6191 0 9.07812 0 6.5C0 3.39453 2.49023 0.875 5.625 0.875C8.73047 0.875 11.25 3.39453 11.25 6.5Z" fill="#E8490F"/>
                          </svg>
                        </div>
                        <div class="text">
                          {{page.elements.location.value}}<br>
                          {{page.elements.city.value}}, {{page.elements.country.value}}
                        </div>
                      </div>
                    </div>
                    <div class="mt-12" v-if="page.elements.website.value.length">
                      <div class="opacity-50">Website</div>
                      <div class="item flex mt-4">
                        <div class="icon shrink-0 w-10 mt-1">
                          <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 11.1875V7.4375H11.25V11.1875C11.25 13.7949 9.14062 15.875 6.5625 15.875H4.6875C2.08008 15.875 0 13.7949 0 11.1875ZM5.15625 0.875V6.5H0V5.5625C0 2.98438 2.08008 0.875 4.6875 0.875H5.15625ZM6.5625 0.875C9.14062 0.875 11.25 2.98438 11.25 5.5625V6.5H6.09375V0.875H6.5625Z" fill="#E8490F"/>
                          </svg>
                        </div>
                        <div class="text">
                          <a :href="page.elements.website.value" target="_blank">{{page.elements.website.value}}</a>
                        </div>
                      </div>
                    </div>
                    <div class="mt-12">
                      <div class="opacity-50">Social</div>
                      <div class="item flex mt-4 gap-4">

                        <a class="text-34 text-white mr-2 block" :href="page.elements.linkedin_url.value" target="_blank" v-if="page.elements.linkedin_url.value.length">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" fill="currentColor"/></svg>
                        </a>
                        <div v-else class="text-34 mr-2 text-black">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" fill="currentColor"/></svg>
                        </div>

                        <a class="text-34 text-white mr-2 block" :href="page.elements.twitter_url.value" target="_blank" v-if="page.elements.twitter_url.value.length">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" fill="currentColor"/></svg>
                        </a>
                        <div v-else class="text-34 mr-2 text-black">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" fill="currentColor"/></svg>
                        </div>

                        <a class="text-34 text-white mr-2 block" :href="page.elements.facebook_url.value" target="_blank" v-if="page.elements.facebook_url.value.length">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" fill="currentColor"/></svg>
                        </a>
                        <div v-else class="text-34 mr-2 text-black">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" fill="currentColor"/></svg>
                        </div>

                        <a class="text-34 text-white mr-2 block" :href="page.elements.instagram_url.value" target="_blank" v-if="page.elements.instagram_url.value.length">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" fill="currentColor"/></svg>
                        </a>
                        <div v-else class="text-34 mr-2 text-black">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" fill="currentColor"/></svg>
                        </div>

                      </div>
                    </div>

                    <div class="mt-16" v-if="page.elements.attend_event_url.value.length">
                      <a :href="page.elements.attend_event_url.value" target="_blank" class="btn-white inline-block">Attend event</a>
                    </div>
                  </div>
                </div>
              </bleed-container>
            </aside>
          </div>
        </div>

      </div>
    </div>

    <!-- full page starts here -->

    <div class="outer-container">
      <div class="inner-container">

        <div class="mt-48 exhibitors" v-if="page.elements.speakers && page.elements.speakers.value.length">
          <h2 class="font-bold uppercase" v-if="page.elements.speakers_heading && page.elements.speakers_heading.value.length">{{page.elements.speakers_heading.value}}</h2>

          <div class="speakers-list mt-12 py-12 border-t border-b border-grey-200">
            <div class="flex flex-wrap -m-8">
              <div class="speaker w-full md:w-1/4 lg:w-1/6 p-8" v-for="speaker in page.elements.speakers.value" :key="speaker">
                <div class="speaker-overlay fixed z-100 inset-0 bg-black bg-opacity-90 flex items-center justify-center" :class="currentSpeaker === speaker ? 'active' : 'inactive'">
                  <div class="speaker-overlay-content relative p-6 bg-white text-main mx-auto w-full max-w-2xl">
                    <button @click="setCurrentSpeaker(null)" class="z-20 bg-white close absolute right-0 top-0 w-20 h-20 flex items-center justify-center">
                      <svg width="16" height="16" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.2754 11.875L21.6582 4.56055C22.0684 4.15039 22.0684 3.39844 21.6582 2.98828L19.9492 1.2793C19.5391 0.869141 18.7871 0.869141 18.377 1.2793L11.0625 8.66211L3.67969 1.2793C3.26953 0.869141 2.51758 0.869141 2.10742 1.2793L0.398438 2.98828C-0.0117188 3.39844 -0.0117188 4.15039 0.398438 4.56055L7.78125 11.875L0.398438 19.2578C-0.0117188 19.668 -0.0117188 20.4199 0.398438 20.8301L2.10742 22.5391C2.51758 22.9492 3.26953 22.9492 3.67969 22.5391L11.0625 15.1562L18.377 22.5391C18.7871 22.9492 19.5391 22.9492 19.9492 22.5391L21.6582 20.8301C22.0684 20.4199 22.0684 19.668 21.6582 19.2578L14.2754 11.875Z" fill="#000"/>
                      </svg>
                    </button>
                    <div class="row z-10">
                      <div class="image relative">
                        <img class="block w-full" :src="modular_content[speaker].elements.avatar.value[0].url" :alt="modular_content[speaker].elements.avatar.value[0].description">
                      </div>
                      <div class="copy mt-6">
                        <h5 class="text-black">{{modular_content[speaker].elements.full_name.value}}</h5>
                        <div class="title block main-text text-sm">
                          {{modular_content[speaker].elements.title.value}}
                        </div>
                        <div class="bio mt-4 text-black" v-html="modular_content[speaker].elements.bio.value"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <button type="button" class="w-full group" @click="setCurrentSpeaker(speaker)">
                <span class="block avatar rounded-full w-full overflow-hidden isolate relative mx-auto">
                  <img class="block absolute left-0 top-0 w-full h-full object-cover" :src="modular_content[speaker].elements.avatar.value[0].url" :alt="modular_content[speaker].elements.avatar.value[0].description">
                </span>
                  <span class="name mt-6 block text-lg font-bold uppercase text-primary-500 group-hover:text-white">
                  {{modular_content[speaker].elements.full_name.value}}
                </span>
                  <span class="title block main-text text-sm">
                  {{modular_content[speaker].elements.title.value}}
                </span>
                </button>
              </div>
            </div>

          </div>
        </div>

        <div class="mt-48 exhibitors" v-if="sortedExhibitors.length">
          <h2 class="font-bold uppercase">Exhibitors</h2>
          <div class="sort flex mt-4 md:-mt-8 justify-between md:justify-end items-center">
            <div class="flex">
              <span class="main-text block mr-4">Sort by</span>
              <select v-model="exhibitorSort" class="width-full bg-black border-b border-white px-0">
                <option value="Alphabetical">Alphabetical</option>
                <option value="Distributor">Distributor</option>
                <option value="Stand">Stand</option>
                <option value="Region">Region</option>
              </select>
            </div>

            <a :href="`/experience/${page.elements.slug.value}/exhibitors`" target="_blank" class="ml-12 flex items-center">
              <svg class="block mr-2" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" fill="currentColor"/></svg>
              Print
            </a>
          </div>
          <div class="mt-12 pt-12 border-t border-b border-grey-200">
            <div class="list">

              <div class="scroller flex flex-wrap overflow-auto pb-12">

                <div class="item w-full h-auto md:w-1/2 lg:w-1/3 p-4" v-for="item in sortedExhibitors" :key="item.system.id">
                  <div class="inner h-full border border-grey-200 p-6">
                    <a :href="`/expo/wineries/${modular_content[item.elements.winery.value[0]].elements.slug.value}`" target="_blank" class="uppercase exhibitor-name font-bold">{{ modular_content[item.elements.winery.value[0]].elements.name.value }}</a>
                    <div class="main-text text-sm">{{ modular_content[item.elements.winery.value[0]].elements.state__zone__region.value }}</div>
                    <div class="stand flex gap-2 items-center">
                      <svg class="block" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="item.elements.stall.value">
                        <path
                            d="M10.3594 3.67188C10.3594 5.06836 8.48047 7.53125 7.66797 8.54688C7.49023 8.80078 7.10938 8.80078 6.93164 8.54688C6.11914 7.53125 4.26562 5.06836 4.26562 3.67188C4.26562 1.99609 5.61133 0.625 7.3125 0.625C8.98828 0.625 10.3594 1.99609 10.3594 3.67188ZM7.3125 4.48438C7.87109 4.48438 8.32812 4.05273 8.32812 3.46875C8.32812 2.91016 7.87109 2.45312 7.3125 2.45312C6.72852 2.45312 6.29688 2.91016 6.29688 3.46875C6.29688 4.05273 6.72852 4.48438 7.3125 4.48438ZM10.791 5.19531C10.8164 5.16992 10.8164 5.14453 10.8418 5.11914L13.7871 3.92578C14.168 3.77344 14.625 4.05273 14.625 4.48438V11.3652C14.625 11.6191 14.4727 11.8477 14.2188 11.9492L10.5625 13.3965V5.72852C10.6387 5.55078 10.7148 5.37305 10.791 5.19531ZM3.80859 5.19531C3.88477 5.37305 3.96094 5.55078 4.0625 5.72852V12.1016L0.8125 13.3965C0.431641 13.5488 0 13.2695 0 12.8379V5.95703C0 5.70312 0.126953 5.47461 0.380859 5.39844L3.47852 4.1543C3.55469 4.50977 3.65625 4.86523 3.80859 5.19531ZM8.30273 9.05469C8.6582 8.62305 9.2168 7.91211 9.75 7.09961V13.4473L4.875 12.0508V7.09961C5.38281 7.91211 5.94141 8.62305 6.29688 9.05469C6.80469 9.71484 7.79492 9.71484 8.30273 9.05469Z"
                            fill="#E8490F"/>
                      </svg>
                      <div class="font-bold uppercase" v-if="item.elements.stall.value">{{ item.elements.stall.value }}</div>
                      <div class="main-text" v-if="item.elements.by.value">by</div>
                      <div class="font-bold" v-if="item.elements.by.value">{{ item.elements.by.value }}</div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div class="cta mt-12" v-if="page.elements.floorplan.value.length">
            <a :href="page.elements.floorplan.value[0].url" target="_blank" class="btn-red-outline inline-block">See the floorplan</a>
          </div>

        </div>


        <div class="wines mt-48" v-if="page.elements.collections.value.length">
          <h2 class="font-bold uppercase">Wines</h2>
          <div class="mt-4 main-text max-w-5xl mt-6" v-html="page.elements.wines_intro_text.value"
               v-if="page.elements.wines_intro_text.value.length > 20"></div>

          <!-- I don't think it makes sense to have a cta here to explore wines, as that links to a collection, and there's already a list of collections -->

          <!-- collections -->
          <div class="mt-16">
            <div class="collection-list -mx-4 mt-24">
              <masonry
                  :cols="{default: 3, 1023: 2, 767: 1}"
                  :gutter="{default: '30px'}"
              >
                <div class="collection-tile w-full p-4" v-for="(item, index) in page.elements.collections.value"
                     :key="`collection${index}}`">
                  <router-link :to="`/discover/collections/${modular_content[item].elements.slug.value}`"
                               class="block text-white winery-inner bg-grey-100 pl-12 pb-12 lg:pl-20 lg:pb-20 group">
                    <div class="image">
                      <div class="image-container relative pb-9/16">
                        <img :src="modular_content[item].elements.image.value[0].url"
                             :alt="modular_content[item].elements.name.value"
                             class="block absolute left-0 top-0 w-full h-full object-cover max-w-none">
                      </div>
                    </div>
                    <div class="title mt-12 text-18 font-bold pr-8">{{ modular_content[item].elements.name.value }}
                    </div>
                    <div class="description mt-4 text-14 pr-8 text-grey-500"
                         v-html="modular_content[item].elements.short_description.value"></div>
                    <div
                        class="cta mt-12 text-14 flex flex-row items-center text-grey-400 uppercase group-hover:text-grey-500 transition-colors">
                      View collection
                      <img src="@/assets/icon-arrow.png" alt=""
                           class="w-8 h-4 block ml-4 group-hover:ml-6 transition-all">
                    </div>
                  </router-link>
                </div>
              </masonry>

            </div>
          </div>
        </div>

        <!-- program -->

        <div class="program mt-48" v-if="days.length">
          <h2 class="font-bold uppercase">Program</h2>

          <div class="program-item" v-for="(day, index) in days" :key="day.system.id">
            <bleed-container :bleed="index % 2 === 0 ? 'left' : 'right'" class="mt-24 relative">
              <div class="image relative h-80 md:absolute md:h-auto md:inset-1 z-10" v-if="day.elements.image.value.length">
                <img :src="`${day.elements.image.value[0].url}?auto=format`" alt="" class="absolute left-0 top-0 w-full h-full object-cover">
              </div>
              <div class="bleed-wrapper relative z-20">
                <div class="bg-black py-12 md:py-40" :class="{'md:ml-11/20 md:pl-12': index % 2 === 0, 'md:mr-11/20 md:pr-12': index % 2 !== 0}">
                  <h4 class="font-bold">Day {{day.elements.day_number.value}}: {{dayjs.utc(day.elements.date.value).tz(day.elements.date.display_timezone).format('ddd DD MMMM h:mma')}}</h4>
                  <div class="local-time main-text mt-4" v-if="dayjs.utc(day.elements.date.value).tz(day.elements.date.display_timezone).format('ddd DD MMMM h:mma') !== dayjs.utc(day.elements.date.value).local().format('ddd DD MMMM h:mma')">{{dayjs.utc(day.elements.date.value).local().format('ddd DD MMMM h:mma')}} ({{dayjs.tz.guess()}})</div>
                  <div class="ics" v-if="day.elements.ics.value.length">
                    <a :href="day.elements.ics.value[0].url" target="_blank" class="text-primary-500 hover:text-white transition-colors flex flex-row items-center mt-4">
                      <svg class="block mr-2 -mt-1" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.6875 1.8125V2.75H8.4375V1.8125C8.4375 1.31445 8.84766 0.875 9.375 0.875C9.87305 0.875 10.3125 1.31445 10.3125 1.8125V2.75H11.7188C12.4805 2.75 13.125 3.39453 13.125 4.15625V5.5625H0V4.15625C0 3.39453 0.615234 2.75 1.40625 2.75H2.8125V1.8125C2.8125 1.31445 3.22266 0.875 3.75 0.875C4.24805 0.875 4.6875 1.31445 4.6875 1.8125ZM0 6.5H13.125V14.4688C13.125 15.2598 12.4805 15.875 11.7188 15.875H1.40625C0.615234 15.875 0 15.2598 0 14.4688V6.5ZM1.875 9.78125C1.875 10.0449 2.08008 10.25 2.34375 10.25H3.28125C3.51562 10.25 3.75 10.0449 3.75 9.78125V8.84375C3.75 8.60938 3.51562 8.375 3.28125 8.375H2.34375C2.08008 8.375 1.875 8.60938 1.875 8.84375V9.78125ZM5.625 9.78125C5.625 10.0449 5.83008 10.25 6.09375 10.25H7.03125C7.26562 10.25 7.5 10.0449 7.5 9.78125V8.84375C7.5 8.60938 7.26562 8.375 7.03125 8.375H6.09375C5.83008 8.375 5.625 8.60938 5.625 8.84375V9.78125ZM9.84375 8.375C9.58008 8.375 9.375 8.60938 9.375 8.84375V9.78125C9.375 10.0449 9.58008 10.25 9.84375 10.25H10.7812C11.0156 10.25 11.25 10.0449 11.25 9.78125V8.84375C11.25 8.60938 11.0156 8.375 10.7812 8.375H9.84375ZM1.875 13.5312C1.875 13.7949 2.08008 14 2.34375 14H3.28125C3.51562 14 3.75 13.7949 3.75 13.5312V12.5938C3.75 12.3594 3.51562 12.125 3.28125 12.125H2.34375C2.08008 12.125 1.875 12.3594 1.875 12.5938V13.5312ZM6.09375 12.125C5.83008 12.125 5.625 12.3594 5.625 12.5938V13.5312C5.625 13.7949 5.83008 14 6.09375 14H7.03125C7.26562 14 7.5 13.7949 7.5 13.5312V12.5938C7.5 12.3594 7.26562 12.125 7.03125 12.125H6.09375ZM9.375 13.5312C9.375 13.7949 9.58008 14 9.84375 14H10.7812C11.0156 14 11.25 13.7949 11.25 13.5312V12.5938C11.25 12.3594 11.0156 12.125 10.7812 12.125H9.84375C9.58008 12.125 9.375 12.3594 9.375 12.5938V13.5312Z" fill="currentColor"/>
                      </svg>
                      Add to calendar
                    </a>
                  </div>
                  <div class="summary mt-6" v-if="day.elements.summary.value.length > 20" v-html="day.elements.summary.value"></div>
                  <div class="cta">
                    <a class="cta mt-6 text-14 flex flex-row items-center text-grey-400 uppercase group hover:text-grey-500 transition-all" :href="`/experience/${page.elements.slug.value}/day${day.elements.day_number.value}`">
                      Read more
                      <svg class="w-8 h-4 block ml-4 group-hover:ml-6 transition-all" width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="5.11523" width="18.8679" height="0.769231" fill="currentColor"/>
                        <rect width="6.85808" height="0.762008" transform="matrix(0.700341 -0.713809 0.700341 0.713809 14.5605 9.85156)" fill="currentColor"/>
                        <rect width="6.85808" height="0.762008" transform="matrix(-0.700341 -0.713809 0.700341 -0.713809 19.3638 6.04395)" fill="currentColor"/>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </bleed-container>
          </div>
        </div>


        <div class="border-t mt-40 border-grey-200 faq-section" v-if="page.elements.faq.value.length">
          <div class="mt-40 mx-auto max-w-5xl">
            <div class="section" v-for="section in page.elements.faq.value"
                 :key="`section_${modular_content[section].system.id}`">
              <h2 class="font-bold mb-12" :id="`faqs_${modular_content[section].system.id}`">{{
                  modular_content[section].elements.heading.value
                }}</h2>
              <div class="faq-item" v-for="item in modular_content[section].elements.faq_items.value"
                   :key="`item_${modular_content[item].system.id}`">
                <h5 class="faq-question" @click="toggleFaqItem">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22.386" height="12.202" viewBox="0 0 22.386 12.202"
                       class="absolute top-8 left-4 transition duration-300">
                    <g id="Action_Down_Arrow_Copy_2" data-name="Action_Down Arrow Copy 2"
                       transform="translate(0.681 0.733)">
                      <path id="Path_Copy_2" data-name="Path Copy 2" d="M0,0,10.85,10.083,21,0" fill="none"
                            stroke="#E8490F" stroke-miterlimit="10" stroke-width="2" />
                    </g>
                  </svg>
                  {{ modular_content[item].elements.question.value }}
                </h5>
                <div class="faq-answer">
                  <div v-html="modular_content[item].elements.answer.value"></div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="border-t mt-40 border-grey-200 faq-section" v-if="page.elements.sponsors.value.length">
          <div class="mt-40 mx-auto">
            
            <experience-sponsors :page="page" :modular-content="modular_content"></experience-sponsors>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Analytics from '@/analytics'
import Kontent from "@/kontent";
import BleedContainer from "../components/BleedContainer.vue";
import WineryVideo from "../components/WineryVideo.vue";
import VueMasonry from 'vue-masonry-css'
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import $ from "jquery";
import ExperienceSponsors from "@/components/ExperienceSponsors";
dayjs.extend(utc);
dayjs.extend(timezone);

Vue.use(VueMasonry);
export default {
  name: 'ExperienceEvent',
  metaInfo() {
    if (!this.page) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {
        property: 'og:title',
        content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
      },
      {property: 'og:description', content: this.page.elements.page__page_description.value},
      {
        name: 'title',
        content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
      },
      {name: 'keywords', content: this.page.elements.page__meta_keywords.value},
      {
        name: 'description',
        content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value
      },
      {name: 'twitter:card', content: 'summary_large_image'},
      {
        name: 'twitter:title',
        content: this.page.elements.page__meta_title.value.length ? this.page.elements.page__meta_title.value : this.page.elements.page__page_title.value
      },
      {
        name: 'twitter:description',
        content: this.page.elements.page__meta_description.value.length ? this.page.elements.page__meta_description.value : this.page.elements.page__page_description.value
      }
    ]

    // if there's an image
    if (this.page.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.page.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.page.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.page.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.page.elements.page__page_type.value[0].name})
    }

    return {
      title: this.page.elements.page__page_title.value,
      meta: meta
    }
  },
  components: {WineryVideo, BleedContainer, ExperienceSponsors},
  props: {},
  data() {
    return {
      page: null,
      modular_content: null,
      exhibitorSort: 'Alphabetical',
      currentSpeaker: null
    }
  },
  mounted() {
    Analytics.trackPage('Experience Event')
    if (this.$auth.isAuthenticated) {
      this.$store.dispatch("getFavouriteProducts")
      this.$store.dispatch("getFavouriteWineries")
    }

    const self = this
    Kontent.getExperienceEvent(this.$route.params.event).then((res) => {
      self.page = res.data.items[0]
      self.modular_content = res.data.modular_content

      setTimeout(function () {
        window.prerenderReady = true
      }, 1000)
    })

    $(document).on('scroll', function(){
      if ($(window).scrollTop() > $('.main-content').offset().top + $('.main-content').height() - 200) {
        $('body').addClass('event-scrolled')
      } else {
        $('body').removeClass('event-scrolled')
      }
    });
  },
  methods: {
    toggleFaqItem(e) {
      if ($(e.target).parents('.faq-item').hasClass('active')) {
        $(e.target).parents('.faq-item').removeClass('active')
        $(e.target).parents('.faq-item').find('.faq-answer').stop().slideUp(400)
      } else {
        $(e.target).parents('.faq-item').addClass('active')
        $(e.target).parents('.faq-item').find('.faq-answer').stop().slideDown(300)
      }
    },
    setCurrentSpeaker(speaker) {
      this.currentSpeaker = speaker
    }
  },
  computed: {
    dayjs() {
      return dayjs
    },
    exhibitors() {
      return this.page.elements.exhibitors_list.value.filter((item) => {return this.modular_content[item].elements.winery.value.length > 0}).map((item) => {
        return this.modular_content[item]
      })
    },
    sortedExhibitors() {
      if (this.exhibitorSort === 'Alphabetical') {
        return [...this.exhibitors].sort((a, b) => {
          return this.modular_content[a.elements.winery.value[0]].elements.name.value.localeCompare(this.modular_content[b.elements.winery.value[0]].elements.name.value)
        })
      } else if (this.exhibitorSort === 'Distributor') {
        return [...this.exhibitors].sort((a, b) => {
          return a.elements.by.value.localeCompare(b.elements.by.value)
        })
      } else if (this.exhibitorSort === 'Stand') {
        return [...this.exhibitors].sort((a, b) => {
          if (parseInt(a.elements.stall.value) < parseInt(b.elements.stall.value)) {
            return -1;
          }
          if (parseInt(a.elements.stall.value) > parseInt(b.elements.stall.value)) {
            return 1;
          }
          // a must be equal to b
          return 0;
        })
      } else if (this.exhibitorSort === 'Region') {
        return [...this.exhibitors].sort((a, b) => {
          return this.modular_content[a.elements.winery.value[0]].elements.state__zone__region.value.localeCompare(this.modular_content[b.elements.winery.value[0]].elements.state__zone__region.value)
        })
      } else {
        return this.exhibitors
      }
    },
    days () {
      return this.page.elements.days.value.map((item) => {
        return this.modular_content[item]
      })
    },
  }
}
</script>

<style lang="scss">
.speaker {
  .bio {
    p {
      margin: 10px 0;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.event-scrolled .speaker-overlay {
  @media (min-width: 768px) {
    padding-bottom: 100px;
  }
}

.speaker-overlay-content {
  max-height: 70vh;
  overflow: auto;
}

.faq-section {
  ul {
    margin: 1em 0;
    padding-left: 15px;

    > li {
      list-style-type: disc;
    }
  }

  ol {
    margin: 1em 0;
    padding-left: 15px;

    > li {
      list-style-type: decimal;
    }
  }
}

body.event-scrolled {
  .sticky-bottom-bar {
    transform: none;
  }
}

.main-text {

  a {
          color: #E8490F;
          transition: color 0.3s ease;
          &:hover {
            color: #FFF;
          }
        }
        ul {
          margin: 20px 0;
          padding-left: 15px;

          > li {
            list-style-type: disc;
          }
        }
        ol {
          margin: 20px 0;
          padding-left: 15px;

          > li {
            list-style-type: decimal;
          }
        }
  
  .content-image {
    margin: 30px 0;
  }
}
</style>
<style scoped lang="scss">
.hero-image-height {
  height: 250px;

  @media(min-width: 768px) {
    height: 450px;
  }

  @media(min-width: 1024px) {
    height: 600px;
  }
}

.speaker {
  .avatar {
    aspect-ratio: 1/1;
    max-width: 200px;
  }

  img {
    transition: all 0.5s ease;
  }

  button:hover {
    img {
      transform: scale(1.05);
    }
  }

  .speaker-overlay {
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.faq-item {
  border-top: 1px solid #232323;
  transition: background-color .4s;

  h5.faq-question {
    position: relative;
    margin: 0;
    padding: 15px 15px 15px 45px;
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    cursor: pointer;
    user-select: none;
    transition: background-color .4s;

    &:hover {
      background-color: #232323;
    }

    svg {
      transform: rotate(-90deg);
    }
  }

  .faq-answer {
    display: none; // collapsed by default
    opacity: .2;
    transition: opacity .4s;

    > div {
      padding: 15px 15px 30px 45px;

      *:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: #E8490F;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    ul li::marker {
      color: #E8490F;
    }
  }

  &.active {
    background-color: #232323;

    .faq-question {
      svg {
        transform: rotate(0deg);
      }
    }

    .faq-answer {
      opacity: 1;
    }

    + .active {
      border-top-color: #000;
    }
  }
}

.page {
  line-height: 1.6;
}

.intro-text {
  line-height: 1.6;
  font-size: 20px;
  font-size: responsive 20px 22px;
  font-range: 768px 1220px;
  font-weight: bold;
}

.exhibitor-name {
  font-size: 16px;
  color: #E8490F;

  &:hover {
    color: #FFF;
  }
}

.main-text {
  color: #9A9A9A;
}

.scroller {
  max-height: 550px;
}


.sticky-bottom-bar {
  background-color: #121212;
  transform: translateY(100%);
  z-index: 2000;

  .btn-white {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
