<template>
  <div class="experience-sponsors">
    <h2 class="font-bold uppercase" v-if="page.elements.sponsors_heading.value.length">{{ page.elements.sponsors_heading.value }}</h2>
    <div class="mt-8">

    <sponsor-links :sponsors="modularContent[page.elements.sponsors.value[0]].elements.sponsors.value" :modular_content="modularContent" :show-heading="false" />
    </div>
  </div>
</template>

<script>
import SponsorLinks from "@/components/SponsorLinks";
export default {
  name: 'LandingPageSponsors',
  components: {SponsorLinks},
  props: {
    page: {
      type: Object,
      default () {
        return null
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    }
  }
}
</script>
